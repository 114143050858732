import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { CheckCircleIcon, XCircleIcon, DownloadIcon, KeyIcon, PackageIcon, ClockIcon } from 'lucide-react';
import { bundleConfig, licenseProductConfig, Product } from './config';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'https://bundleapp.codemam.com';

interface PaymentSuccessResponse {
  success: boolean;
  licenseKey: string | null;
  productId: string;
  error?: string;
}

export default function Success() {
  const [status, setStatus] = useState<'loading' | 'success' | 'error'>('loading');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [licenseKey, setLicenseKey] = useState<string | null>(null);
  const [purchasedProduct, setPurchasedProduct] = useState<Product | null>(null);
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const sessionId = searchParams.get('session_id');

    if (sessionId) {
      const checkPaymentStatus = async () => {
        try {
          const response = await fetch(`${BACKEND_URL}/api/check-payment-status`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ sessionId }),
          });

          if (!response.ok) {
            throw new Error('Failed to check payment status');
          }

          const data: PaymentSuccessResponse = await response.json();

          if (data.success) {
            setStatus('success');
            setLicenseKey(data.licenseKey);
            
            const productInfo = bundleConfig[data.productId] || licenseProductConfig[data.productId];
            setPurchasedProduct(productInfo);
          } else {
            throw new Error(data.error || 'Unknown error occurred');
          }
        } catch (error) {
          setStatus('error');
          setErrorMessage(error instanceof Error ? error.message : 'An unexpected error occurred');
        }
      };

      checkPaymentStatus();
    } else {
      setStatus('error');
      setErrorMessage('No session ID found');
    }
  }, [location]);

  const renderDownloadLinks = () => {
    if (!purchasedProduct) return null;

    if (purchasedProduct.type === 'bundle') {
      return (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
          className="bg-white rounded-lg shadow-md p-6 mt-6"
        >
          <h3 className="text-xl font-semibold mb-4 flex items-center">
            <PackageIcon className="w-6 h-6 mr-2 text-indigo-600" />
            Bundle Contents
          </h3>
          <ul className="space-y-3">
            {Object.entries(purchasedProduct.downloadLinks).map(([extension, link], index) => (
              <motion.li
                key={extension}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.1 * index }}
                className="flex items-center justify-between bg-gray-50 rounded-md p-3 hover:bg-gray-100 transition-colors"
              >
                <span className="font-medium text-gray-700">{extension}</span>
                <a
                  href={link as string}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center text-indigo-600 hover:text-indigo-800 transition-colors"
                >
                  <DownloadIcon className="w-5 h-5 mr-1" />
                  Download
                </a>
              </motion.li>
            ))}
          </ul>
        </motion.div>
      );
    } else if (purchasedProduct.type === 'license') {
      return (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
          className="bg-white rounded-lg shadow-md p-6 mt-6"
        >
          <h3 className="text-xl font-semibold mb-4 flex items-center">
            <DownloadIcon className="w-6 h-6 mr-2 text-indigo-600" />
            Download Software
          </h3>
          <a
            href={purchasedProduct.downloadLinks as string}
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors"
          >
            <DownloadIcon className="w-5 h-5 mr-2" />
            Download {purchasedProduct.name}
          </a>
        </motion.div>
      );
    }

    return null;
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-400 to-indigo-600 flex items-center justify-center p-4">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="bg-white rounded-3xl shadow-2xl p-8 max-w-2xl w-full"
      >
        {status === 'loading' && (
          <div className="text-center">
            <motion.div
              animate={{ rotate: 360 }}
              transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
              className="w-16 h-16 border-4 border-indigo-600 border-t-transparent rounded-full mx-auto"
            ></motion.div>
            <p className="mt-4 text-lg text-gray-600">Checking payment status...</p>
          </div>
        )}

        {status === 'success' && (
          <div className="text-center">
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ type: "spring", stiffness: 260, damping: 20 }}
            >
              <CheckCircleIcon className="w-24 h-24 text-green-500 mx-auto" />
            </motion.div>
            <h2 className="text-3xl font-bold mt-6 mb-2 text-gray-800">Payment Successful!</h2>
            <p className="text-xl text-gray-600 mb-6">Thank you for your purchase.</p>
            
            {purchasedProduct && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.1 }}
                className="bg-gray-50 rounded-lg p-6 mb-6"
              >
                <h3 className="text-2xl font-semibold mb-4 text-indigo-700">{purchasedProduct.name}</h3>
                <p className="text-gray-600 mb-2">{purchasedProduct.description}</p>
                <p className="text-sm text-gray-500 flex items-center justify-center">
                  <ClockIcon className="w-4 h-4 mr-1" />
                  {purchasedProduct.type === 'bundle' ? 'Bundle' : 'License'} Product
                </p>
              </motion.div>
            )}

            {licenseKey && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 }}
                className="bg-indigo-50 rounded-lg p-6 mb-6"
              >
                <h3 className="text-xl font-semibold mb-2 text-indigo-700 flex items-center justify-center">
                  <KeyIcon className="w-6 h-6 mr-2" />
                  Your License Key
                </h3>
                <p className="font-mono text-sm break-all bg-white p-3 rounded border border-indigo-200">
                  {licenseKey}
                </p>
              </motion.div>
            )}

            {renderDownloadLinks()}

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3 }}
              className="mt-8"
            >
              <Link
                to="/"
                className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors"
              >
                Return to Home
              </Link>
            </motion.div>
          </div>
        )}

        {status === 'error' && (
          <div className="text-center">
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ type: "spring", stiffness: 260, damping: 20 }}
            >
              <XCircleIcon className="w-24 h-24 text-red-500 mx-auto" />
            </motion.div>
            <h2 className="text-3xl font-bold mt-6 mb-2 text-gray-800">Payment Error</h2>
            <p className="text-xl text-red-600 mb-6">
              There was an error processing your payment. Please contact support.
            </p>
            {errorMessage && (
              <p className="text-sm text-gray-600 mb-6 bg-red-50 p-3 rounded">Error details: {errorMessage}</p>
            )}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
            >
              <Link
                to="/"
                className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors"
              >
                Return to Home
              </Link>
            </motion.div>
          </div>
        )}
      </motion.div>
    </div>
  );
}

